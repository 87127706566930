<template>
    <section class="section-cards-swiper group/layout-cards-swiper" :class="color">
        <LayoutContained :class="padding">
            <div class="flex justify-between">
                <div
                    v-if="heading"
                    class="flex items-center justify-start"
                    :marginBottom="content ? 'mb-1' : 'mb-0'"
                >
                    <ContentSubheading
                        :heading="heading"
                        :headingColor="headingColor"
                        :headingComponent="headingComponent"
                        :textCentered="textCentered"
                    ></ContentSubheading>
                    <div v-if="badgeText" :class="badgeClasses">{{badgeText}}</div>
                </div>
                <NuxtLink :to="viewAllRoute" v-if="viewAllRoute" class="inline-block pr-2">
                    <span class="flex space-x-1 hover:underline">
                        <span>View All</span>
                        <IconChevronRight :size="4"></IconChevronRight>
                    </span>
                </NuxtLink>
            </div>
            <ContentText
                v-if="content"
                :html="content"
                :contentColor="contentColor"
                :textCentered="textCentered"
            ></ContentText>
            <LayoutCardsSwiper
                v-if="cards && cards.length"
                :contentful="contentful"
                :initialSlide="initialSlide"
                :id="id"
                :class="heading || content ? 'mt-5' : ''"
                :cards="cards"
                :dark="dark"
            ></LayoutCardsSwiper>
            <div v-if="buttonText" class="mt-8" :class="!!textCentered ? 'text-center' : ''">
                <ButtonPrimary
                    :buttonColor="buttonColor || undefined"
                    :buttonRoute="buttonRoute"
                    :buttonText="buttonText"
                    :buttonClick="buttonClick"
                    :buttonClickParams="buttonClickParams"
                ></ButtonPrimary>
            </div>
            <slot name="custom"></slot>
        </LayoutContained>
    </section>
</template>

<script>
export default {
    name: 'SectionCardsSwiper',
    props: {
        id: {
            required: false,
            type: String,
        },
        cards: {
            required: false,
            type: Array,
            default () {
                return [];
            },
        },
        initialSlide: {
            required: false,
            type: Number,
            default: 0,
        },
        padding: {
            required: false,
            default: 'py-8 sm:py-8',
            type: String,
        },
        contentful: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        dark: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        viewAllRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        badgeText: {
            required: false,
            type: String,
        },
        badgeColor: {
            required: false,
            type: String,
        }
    },
    computed: {
        client () {
            return !!import.meta.client;
        },
        server () {
            return !!import.meta.server;
        },
        badgeClasses () {
            const badgeClasses = {};
            badgeClasses['hidden sm:block ml-3 py-0.5 px-4 rounded-full font-semibold'] = true;
            if (this.badgeColor) {
                badgeClasses[this.badgeColor] = true;
            } else {
                badgeClasses['bg-sky-600'] = true;
                badgeClasses['text-white'] = true;
            }
            return badgeClasses;
        },
    },
    methods: {
        hideServer (index) {
            if (index === 0) return '';
            if (index === 1) return 'hidden sm:block';
            if (index === 2) return 'hidden md:block';
            if (index === 3) return 'hidden lg:block';
            if (index === 4) return 'hidden 2xl:block';
            if (index > 4) return 'hidden';
        },
    },
};
</script>
