<template>
    <div class="layout-cards-swiper relative group/layout-cards-swiper">
        <button
            class="swiper-button-prev !pointer-events-auto !-left-5 !hidden !h-12 !w-12 cursor-pointer rounded-full !bg-[#f2f2f2] !text-zinc-900 after:hidden group-hover/layout-cards-swiper:md:!flex"
            :class="uid + '-button-prev'"
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                 stroke="currentColor" class="mr-1 !h-6 !w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
        </button>
        <div class="swiper overflow-hidden relative static-sizing" :class="uid">
            <div class="swiper-wrapper">
                <!-- Slides -->
                <div class="swiper-slide" v-for="(card, index) in cards" :key="'section-cards-card-' + index">
                    <LayoutCard
                        :route="card.route"
                        :image="card.image"
                        :imageAlt="card.imageAlt"
                        :heading="card.heading"
                        :excerpt="card.excerpt"
                        :imageAspectRatio="card.imageAspectRatio"
                        :imageObjectFit="card.imageObjectFit"
                        :imageObjectPosition="card.imageObjectPosition"
                        :imageOverlayLocked="card.imageOverlayLocked"
                        :imageLoading="(card.imageLoading === undefined) && contentful && index === 0 ? 'eager' : card.imageLoading"
                        :fetchPriority="(card.fetchPriority === undefined) && contentful && index === 0 ? 'high' : card.fetchPriority"
                        :dark="dark"
                    ></LayoutCard>
                </div>
            </div>
        </div>
        <button
            class="swiper-button-next !pointer-events-auto !-right-5 !hidden !h-12 !w-12 cursor-pointer rounded-full !bg-[#f2f2f2] !text-zinc-900 after:hidden group-hover/layout-cards-swiper:md:!flex"
            :class="uid + '-button-next'"
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                 stroke="currentColor" class="ml-1 !h-6 !w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
        </button>
    </div>
</template>

<script setup>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '~/../tailwind.config';

const swiper = ref();
const uid = ref();

const {id, cards, initialSlide, dark} = defineProps({
    id: {
        required: false,
        type: String,
    },
    cards: {
        required: false,
        type: Array,
        default () {
            return [];
        },
    },
    contentful: {
        required: false,
        type: [Boolean, Number],
        default: false,
    },
    initialSlide: {
        required: false,
        type: Number,
        default: 0
    },
    dark: {
        required: false,
        type: [Boolean, Number],
        default: false,
    }
});

// noinspection JSValidateTypes
uid.value = id ? `swiper-id${id}` : `swiper-uid-${useId()}`;

// noinspection JSCheckFunctionSignatures
const fullConfig = resolveConfig(tailwindConfig);

function getBreakpointValue(value) {
    return parseInt(fullConfig.theme.screens[value]);
}

async function initSwiper() {
    // Initialise swiper
    const swiper = usePluginSwiper();
    await swiper.init();

    const {$Swiper, $SwiperNavigation} = useNuxtApp();

    // Keep slides per view consistent with LayoutCards
    const breakpoints = {};
    breakpoints[getBreakpointValue('sm')] = {
        slidesPerView: 1.5,
    };
    breakpoints[getBreakpointValue('md')] = {
        freeMode: false,
        slidesPerView: 2.5,
    };
    breakpoints[getBreakpointValue('lg')] = {
        freeMode: false,
        slidesPerView: 4,
    };
    breakpoints[getBreakpointValue('xl')] = {
        freeMode: false,
        slidesPerView: 4,
    };
    breakpoints[getBreakpointValue('2xl')] = {
        freeMode: false,
        slidesPerView: 5,
    };
    swiper.value = new $Swiper('.' + uid.value, {
        modules: [$SwiperNavigation],
        speed: 500,
        initialSlide,
        navigation: {
            nextEl: '.' + uid.value + '-button-next',
            prevEl: '.' + uid.value + '-button-prev',
        },
        slidesPerView: 1.5,
        spaceBetween: 10,
        freeMode: true,
        breakpoints,
        roundLengths: true, // Prevent subpixel rendering
        watchSlidesProgress: true,
        observer: true, // Re-init on DOM changes
        observeParents: true
    });
}

onMounted(() => {
    if (!swiper.value) initSwiper();
});

</script>
