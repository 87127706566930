// noinspection JSValidateTypes
/** @type {import('tailwindcss').Config} */
import pluginForms from '@tailwindcss/forms';
import defaultTheme from 'tailwindcss/defaultTheme';
const colors = defaultTheme.colors;

export default {
    darkMode: 'class',
    content: [
        './nuxt/components/**/*.{js,vue,ts}',
        './nuxt/layouts/**/*.vue',
        './nuxt/pages/**/*.vue',
        './nuxt/plugins/**/*.{js,ts}',
        './nuxt.config.{js,ts}',
    ],
    theme: {
        extend: {
            // Sensible limits: body text -0.02 → 0.05, display text -0.03 → 0.1
            letterSpacing: {
                tight: '-0.02em',
                tighter: '-0.03em',
            },
            zIndex: {
                '1000': '1000',
                '10000': '10000',
            },
            colors: {
                sky: {
                    ...colors.sky,
                    600: 'rgb(0, 124, 189)'
                }
            }
        },
        screens: {
            'sm': '640px',
            'md': '768px',
            'lg': '1100px',
            'xl': '1280px',
            '2xl': '1536px',
            'vertical-tablet': {'raw': '(min-height: 1000px)'}
        },
    },
    plugins: [
        pluginForms,
    ],
};
